import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const DemandDetailPdf = ({ logoStr, data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: "Roboto",
    },
    section: {
      marginBottom: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    demandNoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    logo: {
      width: 140, // Adjust width and height to fit your logo
      height: 40,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
    },
    table: {
      marginBottom: 20,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      borderBottomStyle: "solid",
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
      borderBottomColor: "gray",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    tableCell: {
      flex: 1,
      fontSize: 10,
      padding: 5,
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 20,
    },
    logoPlaceholder: {
      fontSize: 20,
      color: "grey",
      fontWeight: "bold",
      textAlign: "center",
    },
  });
  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            {logoStr ? (
              <Image src={logoStr} style={styles.logo} />
            ) : (
              <Text style={styles.logoPlaceholder}>LOGO</Text>
            )}
          </View>

          <View style={styles.demandNoContainer}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>SATIN ALMA TALEBİ</Text>
              <Text style={styles.title}>{data?.demandNo} </Text>
            </View>
            <Text style={styles.text}>
              Tarih: {formatDate(data?.demandDate)}
            </Text>
            <Text style={styles.text}>
              Geçerlilik Tarihi: {formatDate(data?.expiryDate)}
            </Text>
          </View>
        </View>

    

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableCell}>Ürün Adı</Text>
            <Text style={styles.tableCell}>Miktar</Text>
            <Text style={styles.tableCell}>Birim Tipi</Text>
          </View>
          {data?.demandItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{item.name}</Text>
              <Text style={styles.tableCell}>{item.quantity}</Text>
              <Text style={styles.tableCell}>{item.unitTypeString}</Text>
            </View>
          ))}
        </View>

      </Page>
    </Document>
  );
};

export default DemandDetailPdf;
