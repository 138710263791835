import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../components/modal/SideBarRight";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const DemandAdd = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [demandItems, setDemandItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [title, setTitle] = useState("");
  const [demandName, setDemandName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (user && user.country) {
      setCustomerID(user.country);

      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=30&typeIDs=31&typeIDs=54&typeIDs=1544&typeIDs=1663&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setStockCards(res.data.items);
              const newDataStockCards = res.data?.items?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
                type: item.type,
              }));

              setStockCardsOpt(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...demandItems];
    list[index][name] = value;

    setDemandItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...demandItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    // list[index].unitPrice = stockCardsValue.salesPrice;
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    setDemandItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...demandItems];
    list.splice(index, 1);
    setDemandItems(list);
  };

  const handleServiceAdd = () => {
    setDemandItems([
      ...demandItems,
      {
        quantity: Number(),
        name: "",
        stockCardID: Number(),
        description: "",
      },
    ]);
  };

  //////////////////////////

  const postData = {
    customerID,
    demandNo: "",
    name: demandName,
    title: title,
    demandDate: startDate,
    expiryDate: finishDate,
    type: 0,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    demandItems: demandItems,
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post("/api/Demand/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Talep oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/demands");
      })

      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };

  console.log(postData);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Satın Alma Talepleri", href: "/demands" },
                { label: "Satın Alma Talebi Oluştur", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-14 rounded-[3px] min-h-screen flex justify-between w-[90%] bg-gray-100">
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="title text-3xl font-semibold">
                    Satın Alma Talebi Oluştur
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="demandName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={demandName}
                      onChange={(e) => setDemandName(e.target.value)}
                      type="text"
                      id="demandName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                      placeholder="Seçiniz..."
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ödeme Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                      placeholder="Seçiniz..."
                    />
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5 h-[350px]">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                          Birim Tipi
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {demandItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div>
                              <Select
                                maxMenuHeight={200}
                                onChange={(e) => handleSelectChange(e, index)}
                                options={stockCardsOpt}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>

                          <td className="px-6 py-4">
                            {singleService.unitTypeString ? (
                              <p className="text-[#0C692F] font-semibold text-center">
                                {singleService.unitTypeString}
                              </p>
                            ) : (
                              <p className="text-[#0C692F] font-semibold text-center">
                                -
                              </p>
                            )}
                          </td>

                          <td className="px-6 py-4 text-center">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg my-5 h-10 w-24"
                      title="Ekle"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default DemandAdd;
