import React, { useEffect, useState } from "react";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import Footer from "../../components/bars/Footer";
import { LuSend } from "react-icons/lu";
import DemandPdfModal from "../demands/DemandPdfModal";
import DemandPdfDetailModal from "../demands/DemandPdfDetailModal";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

const OffersWithDemands = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [openPdfDetailModal, setOpenPdfDetailModal] = useState(false);
  const [dataItem, setDataItem] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [openDetailIds, setOpenDetailIds] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    title: "desc",
    districtName: "desc",
    address: "desc",
    taxOffice: "desc",
    taxNumber: "desc",
    demandNo: "desc",
    demandDate: "desc",
    expiryDate: "desc",
    totalPrice: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=2`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (res.data.assigmentID === undefined) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Offer/GetOfferswithDemands?customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
            )
            .then((res) => {
              setData(res.data.items);
              res.data.items.demandDate = formatDate(res.data.items.demandDate);
              res.data.items.expiryDate = formatDate(res.data.items.expiryDate);
              setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "title" ||
          columnName === "address" ||
          columnName === "taxOffice" ||
          columnName === "taxNumber" ||
          columnName === "demandNo" ||
          columnName === "demandDate" ||
          columnName === "expiryDate" ||
          columnName === "totalPrice"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  // ITEMS TO EXCEL
  const handleExportData = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPdfData(response.data);

      const worksheet = XLSX.utils.json_to_sheet(data);

      // Örneğin, A, B, C sütunları için genişlik ayarı
      worksheet["!cols"] = [
        { wch: 10 }, // A sütunu genişliği
        { wch: 10 }, // B sütunu genişliği
        { wch: 10 }, // C sütunu genişliği
        { wch: 10 }, // D sütunu genişliği
        { wch: 10 }, // E sütunu genişliği
        { wch: 20 }, // F sütunu genişliği
        { wch: 20 }, // G sütunu genişliği
        { wch: 100 }, // H sütunu genişliği
        { wch: 60 }, // I sütunu genişliği
        { wch: 10 }, // J sütunu genişliği
        { wch: 10 }, // K sütunu genişliği
        { wch: 20 }, // L sütunu genişliği
        { wch: 20 }, // M sütunu genişliği
        { wch: 20 }, // N sütunu genişliği
        { wch: 20 }, // O sütunu genişliği
        { wch: 10 }, // P sütunu genişliği
        { wch: 10 }, // Q sütunu genişliği
        { wch: 10 }, // R sütunu genişliği
        { wch: 10 }, // S sütunu genişliği
        { wch: 20 }, // T sütunu genişliği
        { wch: 10 }, // U sütunu genişliği
        { wch: 10 }, // V sütunu genişliği
        { wch: 10 }, // W sütunu genişliği
        { wch: 100 }, // X sütunu genişliği
        { wch: 50 }, // Y sütunu genişliği
        { wch: 10 }, // Z sütunu genişliği
        { wch: 100 }, // AA sütunu genişliği
        { wch: 50 }, // AB sütunu genişliği
        { wch: 10 }, // AC sütunu genişliği
        { wch: 20 }, // AD sütunu genişliği
        { wch: 20 }, // AE sütunu genişliği
        { wch: 40 }, // AF sütunu genişliği
        { wch: 10 }, // AG sütunu genişliği
        // ve böyle devam eder...
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const fileName = `${response.data}.xlsx`; // Dosya adını ve uzantısını belirtiyoruz
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const fetchDocumentNumber = async () => {
    setLoading(true);
    try {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token"); // Token'ı localStorage'dan alabilirsiniz
      const response = await api.get(
        `/api/Common/GetDocumentNumber?customerID=${customerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header'ını ekliyoruz
          },
        }
      );

      // Mevcut veri ile API'den gelen veriyi birleştiriyoruz

      setPdfData(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfModal(true); // Modal'ı açıyoruz
    }
  };

  const handleDelete = async (offerID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0C692F",
        cancelButtonColor: "#B10F0F",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Offer/DeleteOffer/${offerID}?customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.offerID !== offerID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Teklif silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Teklif silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const fetchSingleItem = async (id) => {
    setLoading(true);

    try {
      const response = await api.get(
        `/api/Demand/Get?id=${id}&customerID=${customerID}`
      );
      setDataItem(response.data);
    } catch (error) {
      console.error("Error fetching document number:", error);
    } finally {
      setLoading(false);
      setOpenPdfDetailModal(true); // Modal'ı açıyoruz
    }
  };

  // Detay açma işlevi
  const openDetail = (demandID) => {
    setOpenDetailIds((prevIds) => [...prevIds, demandID]);
  };

  // Detay kapatma işlevi
  const closeDetail = (demandID) => {
    setOpenDetailIds((prevIds) => prevIds.filter((id) => id !== demandID));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <>
          <div className="add-button pt-6">
            <div className="mb-5 flex items-center gap-2">
              <FaStarOfLife size={15} />
              <h1 className="text-3xl font-semibold title">Talep Teklifleri</h1>
            </div>
            <div className="flex justify-between pt-2">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Talep ara.."
                value={searchTerm}
              />
            </div>
          </div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase bg-[#050A27] sticky top-0">
                <tr>
                <th scope="col" className="px-6 py-3 "></th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    Talep Adı {sortOrder.name === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("title")}
                  >
                    Başlık {sortOrder.title === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("demandNo")}
                  >
                    Talep Numarası {sortOrder.demandNo === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("demandDate")}
                  >
                    Tarih {sortOrder.demandDate === "asc" ? "▲" : "▼"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 cursor-pointer"
                    onClick={() => handleSort("expiryDate")}
                  >
                    Son Tarih {sortOrder.expiryDate === "asc" ? "▲" : "▼"}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    Rev.
                  </th>

                  
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.filter(
                      (item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.title
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.demandNo
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.demandDate
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.expiryDate
                          ?.toString()
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`bg-white border-b hover:bg-gray-50 ${
                            item.offers.length > 0 ? "cursor-pointer" : ""
                          }`}
                          onClick={() =>
                            openDetailIds.includes(item.demandID)
                              ? closeDetail(item.demandID)
                              : openDetail(item.demandID)
                          }
                        >
                                 {item.offers.length > 0 ? (
                            <td className="text-center px-5 py-4">
                              <FaAngleDown
                                className={`transform transition-transform ${
                                  openDetailIds.includes(item.demandID)
                                    ? "rotate-180"
                                    : ""
                                }`}
                                size={18}
                                color="gray"
                              />
                            </td>
                          ) : (
                            <td className="px-6 py-4"></td>
                          )}
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            title={item.name}
                          >
                            {truncateText(item.name, 30)}
                          </th>
                          <td title={item.title} className="px-6 py-4">
                            {truncateText(item.title, 30)}
                          </td>
                          <td className="px-6 py-4">{item.demandNo}</td>
                          <td className="px-6 py-4">
                            {formatDate(item.demandDate)}
                          </td>
                          <td className="px-6 py-4">
                            {formatDate(item.expiryDate)}
                          </td>
                          <td className="flex items-center px-6 py-4 space-x-3">
                            <button>
                              <Link
                                title="Teklif oluştur"
                                className="font-medium text-[#0C692F] hover:underline"
                                to={`/offerWithDemand/${item.demandID}`}
                              >
                                <FaPlus
                                  className="hover:scale-110 transition-transform duration-200"
                                  size={24}
                                />
                              </Link>
                            </button>
                          </td>
                          <td className="px-3 py-4 text-center font-semibold text-base">
                            {item.revisionCount}
                          </td>
                   
                        </tr>
                        {openDetailIds.includes(item.demandID) && // Detay açık ise alt satırları göster
                          item.offers.map((detail, idx) => (
                            <tr
                              key={idx}
                              className={`border-b hover:bg-gray-200 bg-gray-100`}
                            >
                              <td className="px-5 py-4"></td>

                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {truncateText(detail.name, 30)}
                              </th>
                              <td title={detail.title} className="px-6 py-4">
                                {truncateText(detail.title, 30)}
                              </td>
                              <td className="px-6 py-4">{detail.offerNo}</td>
                              <td className="px-6 py-4">
                                {formatDate(detail.offerDate)}
                              </td>
                              <td className="px-6 py-4">
                                {formatDate(detail.expiryDate)}
                              </td>
                              <td className="px-6 py-4">
                                <div className="flex items-center space-x-3">
                                  {permissions.showPricePermission && (
                                    <a
                                      onClick={() =>
                                        fetchSingleItem(detail.offerID)
                                      }
                                      className="font-medium text-[#050A27] hover:underline cursor-pointer"
                                      title="PDF çıktı al"
                                    >
                                      <FaRegFilePdf
                                        className="hover:scale-110 transition-transform duration-200"
                                        size={24}
                                      />
                                    </a>
                                  )}
                                  {permissions.reportPermission && (
                                    <Link to={`/offerDetail/${detail.offerID}`}>
                                      <a
                                        title="Teklif detay"
                                        className="font-medium text-[#050A27] hover:underline"
                                      >
                                        <FaRegEye
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    </Link>
                                  )}
                                  {permissions.updatePermission && (
                                    <Link to={`/offerEdit/${detail.offerID}`}>
                                      <a
                                        title="Teklif düzenle"
                                        className="font-medium text-[#050A27]  hover:underline"
                                      >
                                        <TbEdit
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    </Link>
                                  )}
                                  {permissions.deletePermission && (
                                    <button
                                      onClick={() => {
                                        handleDelete(
                                          detail.offerID,
                                          detail.customerID
                                        );
                                      }}
                                    >
                                      <a
                                        title="Teklif sil"
                                        className="font-medium text-[#050A27]  hover:underline"
                                      >
                                        <RiDeleteBin6Line
                                          className="hover:scale-110 transition-transform duration-200"
                                          size={24}
                                        />
                                      </a>
                                    </button>
                                  )}
                                </div>
                              </td>
                            <td className="px-6 py-4"></td>

                            </tr>
                          ))}
                      </React.Fragment>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="px-6 py-4 text-center text-2xl font-medium"
                    >
                      Henüz kayıtlı bir veri yoktur.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav
            className="mt-2 float-right my-5"
            aria-label="Page navigation example"
          >
            <ul className="flex items-center -space-x-px h-10 text-sm">
              <li>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`flex items-center justify-center px-4 h-10 ${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </button>
              </li>
              {renderPageNumbers()}
              <li>
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center justify-center pl-4 h-10 ${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-gray-500"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-2.5 h-2.5 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      {openPdfModal === true && (
        <DemandPdfModal
          data={data}
          pdfData={pdfData}
          onCancel={() => setOpenPdfModal(false)}
        />
      )}
      {openPdfDetailModal === true && (
        <DemandPdfDetailModal
          data={dataItem}
          onCancel={() => setOpenPdfDetailModal(false)}
        />
      )}
      <Footer />
    </>
  );
};

export default OffersWithDemands;
