import React, { useEffect, useState } from "react";
import Header from "../../../components/bars/Header";
import Sidebars from "../../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../../api/api";
import { AiOutlinePlus } from "react-icons/ai/";
import SideBarRight from "../../../components/modal/SideBarRight";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../../../context/AuthContext";
import Footer from "../../../components/bars/Footer";
import LoadingSpinner from "../../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../../components/bars/Breadcrumb";
import { useTranslation } from "react-i18next";

const PurchaseOfferAdd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offerItems, setOfferItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [deadLine, setDeadLine] = useState(new Date());
  const [vat, setVat] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [stockCardsOpt, setStockCardsOpt] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOpt, setAccountsOpt] = useState([]);
  const [title, setTitle] = useState("");
  const [offerName, setOfferName] = useState("");
  const [openSideModal, setOpenSideModal] = useState(false);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=4`
        )
        .then((res) => {
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.insertPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }
          api
            .get(
              `/api/Account/GetAccounts?customerID=${user.country}&typeID=26&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setAccounts(res.data.items);
              const newDataAccounts = res.data?.items?.map((item) => ({
                value: item.accountID,
                label: item.name,
              }));

              setAccountsOpt(newDataAccounts);
            })
            .catch((err) => console.log(err.data));

          api
            .get(
              `/api/StockCard/GetStockCards?customerID=${user.country}&typeIDs=28&typeIDs=29&typeIDs=1663&pageNumber=1&pageSize=99999`
            )
            .then((res) => {
              setStockCards(res.data.items);
              const newDataStockCards = res.data?.items?.map((item) => ({
                value: item.stockCardID,
                label: `${item.code} - ${item.name}`,
                type: item.type,
              }));

              setStockCardsOpt(newDataStockCards);
            })
            .catch((err) => console.log(err.data));

          api
            .get(`/api/Common/GetKeywords?customerID=${user.country}`)
            .then((res) => {
              const newData = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setCustomerKey(newData);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetVat")
            .then((res) => {
              const newDataVat = res.data?.map((item) => ({
                value: item,
                label: item,
              }));
              setVat(newDataVat);
            })
            .catch((err) => console.log(err.data));

          api
            .get("/api/Common/GetCurrencies")
            .then((res) => {
              const newDataCurrency = res.data?.map((item) => ({
                value: item.currencyID,
                label: item.name,
              }));

              setCurrency(newDataCurrency);
            })
            .catch((err) => console.log(err.data))
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...offerItems];
    list[index][name] = value;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //ara toplam
    let altToplam = list[index].quantity * list[index].unitPrice;
    list[index].price = altToplam;

    //KDV
    list[index].taxPrice = tax;

    setOfferItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...offerItems];
    list[index].name = e.label;
    list[index].stockCardID = e.value;
    const stockCardsValue = stockCards.find(
      (item) => item.stockCardID === e.value
    );
    list[index].unitPrice = stockCardsValue.salesPrice;
    list[index].unitTypeString = stockCardsValue.unitTypeString;
    setOfferItems(list);
  };
  const handleSelectChangeVat = (e, index) => {
    const list = [...offerItems];
    list[index].taxRate = e;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //KDV
    list[index].taxPrice = tax;

    setOfferItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...offerItems];
    list.splice(index, 1);
    setOfferItems(list);
  };

  const handleServiceAdd = () => {
    setOfferItems([
      ...offerItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        stockCardID: "",
      },
    ]);
  };
  const handleManuelServiceAdd = () => {
    setOfferItems([
      ...offerItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        stockCardID: 0,
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find(
    (item) => item.accountID === selectedAccount.value
  );

  ////////calculations
  const resultDiscount = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.discount))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultTotalPrice = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.totalPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultSubTotal = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.price))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultKdvTotal = offerItems?.length
    ? offerItems
        .map((sayi) => Number(sayi.taxPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const postData = {
    customerID,
    accountID: selectedAccount.value,
    currencyID: selectedCurrency.value,
    offerNo: "",
    name: offerName,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    offerDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    deadLine,
    type: 3786,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    offerItems,
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
  
    // Token'ı localStorage veya sessionStorage'dan al
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  
    // Token yoksa hata göster
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Yetkilendirme Hatası",
        text: "Lütfen tekrar giriş yapın.",
      });
      setLoading(false);
      return;
    }
  
    // API çağrısını yap
    api
      .post("/api/Offer/Post/Post", postData, {
        headers: {
          Authorization: `Bearer ${token}`, // Yetkilendirme başlığını ekle
        },
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Teklif oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/offers");
      })
      .catch((err) => {
        console.log(err);
        const errorMessageKey = err.response?.data;
        const translatedError = errorMessageKey
          ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
          : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });
        Swal.fire(translatedError);
      })
      .finally(() => setLoading(false));
  };
  

  console.log(postData);

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />

        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Satın Alma Teklifleri", href: "/purchaseOffers" },
                { label: "Satın Alma Teklifi Oluştur", href: "" },
              ]}
            />
            <div className="p-12 mx-auto my-20 rounded-[3px] min-h-screen flex justify-between w-[90%] bg-gray-100">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">
                    Satın Alma Teklifi Oluştur
                  </h1>
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık
                    </label>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="offerName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı
                    </label>
                    <input
                      value={offerName}
                      onChange={(e) => setOfferName(e.target.value)}
                      type="text"
                      id="offerName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setSelectedAccount(e)}
                      value={selectedAccount}
                      options={accountsOpt}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      classNamePrefix="custom-select"
                      placeholder="Seçiniz..."
                    />
                  </div>

                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        htmlFor="visitors"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Para Birimi
                      </label>
                      <Select
                        maxMenuHeight={200}
                        onChange={(e) => setSelectedCurrency(e)}
                        value={selectedCurrency}
                        options={currency}
                        classNamePrefix="custom-select"
                        placeholder="Seçiniz..."
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="deadLine"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Termin Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={deadLine}
                      onChange={(date) => setDeadLine(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ödeme Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                </div>

                <div className="relative overflow-x-auto mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-white uppercase bg-[#050A27]">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Tipi
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İskonto
                        </th>
                        <th scope="col" className="px-6 py-3">
                          KDV
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {offerItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          {singleService.stockCardID === 0 ? (
                            <td className="px-6 py-4">
                              <div className="w-[180px]">
                                <input
                                  value={singleService.name}
                                  onChange={(e) =>
                                    handleServiceChange(e, index)
                                  }
                                  name="name"
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  required
                                />
                              </div>
                            </td>
                          ) : (
                            <td className="px-6 py-4">
                              <div className="w-[180px]">
                                <Select
                                  maxMenuHeight={200}
                                  onChange={(e) => handleSelectChange(e, index)}
                                  options={stockCardsOpt}
                                  classNamePrefix="custom-select"
                                />
                              </div>
                            </td>
                          )}

                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            {singleService.unitTypeString ? (
                              <p className="text-[#0C692F] font-semibold text-center">
                                {singleService.unitTypeString}
                              </p>
                            ) : (
                              <p className="text-[#0C692F] font-semibold text-center">
                                -
                              </p>
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.discount}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="discount"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <div className="w-[100px]">
                              <Select
                                maxMenuHeight={200}
                                onChange={(e) =>
                                  handleSelectChangeVat(e.value, index)
                                }
                                options={vat}
                                classNamePrefix="custom-select"
                                placeholder="Seçiniz..."
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.totalPrice}
                              readOnly
                              name="totalPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-[#B10F0F] hover:bg-[#b10f0fe1] text-white font-bold py-2 px-4 rounded-lg m-5"
                              title="Sil"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <div className="my-5 flex gap-4">
                      <button
                        onClick={handleServiceAdd}
                        type="button"
                        className="bg-[#050A27] hover:bg-[#050a27d7] text-white font-bold py-2 px-4 rounded-lg h-10 w-24"
                        title="Ekle"
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                      <button
                        onClick={handleManuelServiceAdd}
                        type="button"
                        className="bg-[#0C692F] hover:bg-[#0c6930e3] text-white font-bold py-2 px-4 rounded-lg w-36"
                        title="Ekle"
                      >
                        Manuel Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </div>
                  </table>

                  <div className="resultMoney">
                    <div className="resultMoneyInfo">
                      <h1>Ara Toplam :</h1>
                      <p>{resultSubTotal}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Satır İndirimi :</h1>
                      <p>{resultDiscount}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>KDV :</h1>
                      <p>{resultKdvTotal}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Toplam :</h1>
                      <p>{resultTotalPrice}</p>
                      <span>{selectedCurrency.label}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="butonCl text-white bg-[#0C692F] hover:bg-[#0c6930e3] focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center mb-2 h-12"
                    title="Kaydet"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
              <div>
                <button className="absolute top-36 right-0 bg-[#050A27] rounded-l-lg">
                  <AiOutlinePlus
                    size={40}
                    color="white"
                    onClick={() => setOpenSideModal(true)}
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        {openSideModal === true && (
          <SideBarRight onCancel={() => setOpenSideModal(false)} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default PurchaseOfferAdd;
