import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import logo from "../../assets/images/logo.png";
import api from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import { FaRegFilePdf } from "react-icons/fa6";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDetailPDF from "./InvoiceDetailPdf";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";
import Breadcrumb from "../../components/bars/Breadcrumb";
/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import doc from "../../assets/images/docimg.png";
import { useTranslation } from "react-i18next";

const InvoiceDetail = () => {
  const { user } = useAuth();
  const params = useParams();
  const { t } = useTranslation();
  const [customerID, setCustomerID] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [logoStr, setLogoStr] = useState(null);
  const [file, setFile] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      setCustomerID(user.country);
      api
        .get(
          `/api/User/GetRolesModulePermission?customerID=${user.country}&roleID=${user.role}&moduleID=3`
        )
        .then((res) => {
          setPermissions(res.data);
          // İlgili veriyi kontrol et
          if (
            res.data.assigmentID === undefined ||
            !res.data.reportPermission
          ) {
            // SweetAlert2 ile uyarıyı göster ve yönlendir
            Swal.fire({
              icon: "error",
              title: "Yetkiniz Yok",
              text: "Bu sayfaya yetkiniz yoktur.",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                // Yönlendirme işlemi
                navigate("/dashboards");
              }
            });
            return; // Diğer API çağrılarını atla
          }

          api
            .get(`/api/Customer/GetCustomerLogo?id=${user.country}`)
            .then((res) => {
              setLogoStr(res.data);
            })
            .catch((err) => {
              console.log(err.data);
            });

          api
            .get(`/api/Invoice/Get?id=${params.id}&customerID=${user.country}`)
            .then((res) => {
              setData(res.data);
              setDataImages(res.data.imageFiles);
              res.data.invoiceDate = formatDate(res.data.invoiceDate);
              res.data.expiryDate = formatDate(res.data.expiryDate);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Hata",
                text: `${err.response.data}`,
                confirmButtonText: "Tamam",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Yönlendirme işlemi
                  navigate("/dashboards");
                }
              });
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log(err.data));
    }
  }, [user]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=108&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi!",
        showConfirmButton: false,
        timer: 1500,
      });

      // Başarı durumunda SweetAlert'ten sonra sayfanın yenilenmesi
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Hata alındı: ", error);
      const errorMessageKey = error.response?.data;
      const translatedError = errorMessageKey
        ? t(`errors.${errorMessageKey}`, { defaultValue: errorMessageKey })
        : t("errors.UnknownError", { defaultValue: "Bilinmeyen bir hata" });

      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${translatedError}`,
        showConfirmButton: false,
        timer: 1500,
      });

      // Hata durumunda SweetAlert'ten sonra sayfanın yenilenmesi (isteğe bağlı)
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      <Header />

      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb
              breadcrumbData={[
                { label: "Satış Faturaları", href: "/invoices" },
                { label: data?.title || "Fatura Detayı", href: "" },
              ]}
            />
            <div className="demandDetailCont mx-auto my-12">
              {permissions.showPricePermission && (
                <div className="absolute right-0 pr-5">
                  <PDFDownloadLink
                    document={
                      <InvoiceDetailPDF logoStr={logoStr} data={data} />
                    }
                    fileName={`${data.invoiceNo || "FORM"}.pdf`}
                  >
                    {({ loading }) =>
                      loading ? (
                        <a className="text-lg font-medium text-red-600 hover:underline">
                          Yükleniyor...
                        </a>
                      ) : (
                        <a className="text-lg font-medium text-red-600 hover:underline flex gap-2">
                          <FaRegFilePdf size={30} />
                          Proforma fatura çıktı al.
                        </a>
                      )
                    }
                  </PDFDownloadLink>
                </div>
              )}

              <div className="demandPage">
                <div className="page-top">
                  <div className="page-top-logo">
                    <img className="w-[200px] mb-3" src={logoStr} alt="" />
                    <p>Adres: {data?.account?.address}</p>
                  </div>
                  <div className="page-top-info">
                    <h1>PROFORMA FATURA </h1>
                    <h1>{data?.invoiceNo}</h1>
                    <p>
                      <span>Tarih :</span> {data?.invoiceDate}
                    </p>
                    <p>
                      <span>Geçerlilik Tarihi :</span> {data?.expiryDate}
                    </p>
                  </div>
                </div>
                <div className="page-mid-info">
                  <p>
                    Cari:{" "}
                    <span
                      style={{ fontWeight: "600", color: "rgb(63, 63, 63)" }}
                    >
                      {data?.account?.name}
                    </span>
                  </p>
                  <p>Vergi Dairesi: {data?.taxOffice}</p>
                  <p>Vergi No: {data?.taxNumber}</p>
                  <p>Tel No: {data?.account?.telephone}</p>
                  <p>E-mail: {data?.account?.email}</p>
                </div>

                <div className="page-mid-items relative overflow-x-auto my-8">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim fiyatı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Tipi
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Vergi Fiyatı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Vergi Oranı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam Fiyat
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.invoiceItems.map((item, index) => (
                        <tr key={index} className="bg-white border-b">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {item.name}
                          </th>
                          <td className="px-6 py-4">{item.quantity}</td>
                          {permissions.showPricePermission ? (
                            <td className="px-6 py-4">{item.unitPrice}</td>
                          ) : (
                            <td className="px-6 py-4">-</td>
                          )}
                          {permissions.showPricePermission ? (
                            <td className="px-6 py-4">{item.unitTypeString}</td>
                          ) : (
                            <td className="px-6 py-4">-</td>
                          )}
                          {permissions.showPricePermission ? (
                            <td className="px-6 py-4">{item.taxPrice}</td>
                          ) : (
                            <td className="px-6 py-4">-</td>
                          )}
                          {permissions.showPricePermission ? (
                            <td className="px-6 py-4">{item.taxRate}</td>
                          ) : (
                            <td className="px-6 py-4">-</td>
                          )}

                          {permissions.showPricePermission ? (
                            <td className="px-6 py-4">{item?.totalPrice}</td>
                          ) : (
                            <td className="px-6 py-4">-</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {permissions.showPricePermission && (
                  <div className="page-bottom flex flex-col md:flex-row justify-between items-start gap-6">
                    <div className="w-full md:w-[60%]">
                      <p>
                        <span className="font-semibold">Açıklama :</span>{" "}
                        {data?.description}
                      </p>
                    </div>
                    <div className="total-detail w-full md:w-[40%]">
                      <h1 className="flex justify-between">
                        <span>Ara Toplam:</span>
                        <span>
                          {data?.price} {data?.currencyName}
                        </span>
                      </h1>
                      {data?.lineDiscounts > 0 && (
                        <h1 className="flex justify-between">
                          <span>İskonto:</span>
                          <span>
                            {data?.lineDiscounts} {data?.currencyName}
                          </span>
                        </h1>
                      )}
                      <h1 className="flex justify-between">
                        <span>KDV:</span>
                        <span>
                          {data?.taxPrice} {data?.currencyName}
                        </span>
                      </h1>
                      <h1 className="flex justify-between">
                        <span>Toplam:</span>
                        <span>
                          {data?.totalPrice} {data?.currencyName}
                        </span>
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Splide
                  options={{
                    autoWidth: true,
                    gap: 100,
                    pagination: false,
                    padding: 100,
                  }}
                  aria-label="My Favorite Images"
                >
                  {dataImages?.map((item, index) => {
                    const imageName = item.split("/").pop();
                    const altText = imageName.split("_").pop().split(".")[0];
                    // Resim URL'si kontrolü
                    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                    return (
                      <SplideSlide key={index}>
                        {isImage ? (
                          <Link to={item}>
                            <img
                              className="max-w-[200px] cursor-pointer"
                              src={item}
                              alt={`Image ${index + 1}`}
                            />
                          </Link>
                        ) : (
                          <Link to={item}>
                            <img
                              className="max-w-[100px] cursor-pointer"
                              src={`${doc}`}
                            />
                          </Link>
                        )}
                        <p className="text-center font-medium">
                          {truncateText(altText, 10)}
                        </p>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </div>
              <div className="flex items-center w-full mt-10">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
                  id="multiple_files"
                  type="file"
                  multiple
                  onChange={handleChangeFile}
                />
                <button
                  type="button"
                  className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
                  onClick={() => handleDocSubmit()}
                >
                  Yükle
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default InvoiceDetail;
