import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Footer from "../../components/bars/Footer";
import LoadingSpinner from "../LoadingSpinner";
import { FaStarOfLife } from "react-icons/fa6";
import Breadcrumb from "../../components/bars/Breadcrumb";

const Notifications = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını depolamak için
  const [pageSize, setPageSize] = useState(20);
  const [sortOrder, setSortOrder] = useState({
    name: "desc",
    contactName: "desc",
    telephone: "desc",
    email: "desc",
    cityName: "desc",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token") || sessionStorage.getItem("token"));

    if (user && user.country) {
      api
        .get(
          `/api/UserNotification/GetNotifications?userID=${user.nameidentifier}&customerID=${user.country}&pageNumber=${currentPage}&pageSize=${pageSize}`
        )
        .then((res) => {
          setData(res.data.items);
          setTotalPages(res.data.totalPages);
        })
        .catch((err) => console.log(err.data))
        .finally(() => setLoading(false));
    }
  }, [user, currentPage, pageSize]);

  ///////////////////////// PAGING
  const nextPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)); // Son sayfayı aşmamak için kontrol
  };

  const prevPage = () => {
    setLoading(true);
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5 && currentPage > 3) {
      startPage = currentPage - 2;
      endPage = Math.min(currentPage + 2, totalPages);
    } else {
      endPage = Math.min(totalPages, 5);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`flex items-center justify-center px-4 h-10 leading-tight ${
              currentPage === i
                ? "text-blue-600 bg-blue-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }`}
            onClick={() => goToPage(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
  };

  const handleSort = (columnName) => {
    const sortOrderCopy = { ...sortOrder };
    sortOrderCopy[columnName] =
      sortOrder[columnName] === "asc" ? "desc" : "asc";
    setSortOrder(sortOrderCopy);

    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (
          columnName === "name" ||
          columnName === "contactName" ||
          columnName === "telephone" ||
          columnName === "email" ||
          columnName === "cityName"
        ) {
          if (sortOrderCopy[columnName] === "asc") {
            return a[columnName]?.localeCompare(b[columnName]);
          } else {
            return b[columnName]?.localeCompare(a[columnName]);
          }
        } else {
          // Sayısal sütunlar için sıralama işlevini çağır
          return numericSort(
            a[columnName],
            b[columnName],
            sortOrderCopy[columnName]
          );
        }
      });
      return sortedData;
    });
  };

  const numericSort = (a, b, sortOrder) => {
    if (sortOrder === "asc") {
      return a - b;
    } else {
      return b - a;
    }
  };
  /////////////////////////////////////////////

  const handleClick = (notificationID, customerID, linkTo) => {
    api
      .put(
        `/api/UserNotification/PutNotificationStatus/PutNotificationStatus?notificationID=${notificationID}&customerID=${customerID}`
      )
      .then((res) => {
        navigate(linkTo);
      });
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex-grow">
            <Breadcrumb breadcrumbData={[{ label: "Bildirimler", href: "" }]} />
            <div className="m-12 min-h-screen">
              <div className="add-button">
                <div className="mb-5 flex items-center gap-2">
                  <FaStarOfLife size={15} />
                  <h1 className="text-3xl font-semibold title">Bildirimler</h1>
                </div>
                <div className="flex">
                  <input
                    className="w-96 block  p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                    type="text"
                    placeholder="Bildirim ara.."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>

              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-white uppercase bg-[#050A27] top-0 sticky">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Bildirim
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Tarih
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Saat
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ?.filter((item) =>
                        item.notification
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const tarihNesnesi = new Date(item.insertDate);
                        const gun = tarihNesnesi.getDate();
                        const ay = (tarihNesnesi.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const yil = tarihNesnesi.getFullYear().toString();
                        const saat = tarihNesnesi
                          .getHours()
                          .toString()
                          .padStart(2, "0");
                        const dakika = tarihNesnesi
                          .getMinutes()
                          .toString()
                          .padStart(2, "0");

                        // Tarihi istediğiniz formatta oluşturun
                        const formatliTarih = `${gun}/${ay}/${yil}`;
                        const newClock = `${saat}:${dakika}`;
                        const rowClass =
                          item.status === 1 ? "bg-gray-50" : "bg-white";

                        // switch case ile yönlendirme yapılıyor
                        let linkTo = "/notifications"; // Varsayılan değer

                        switch (item.type) {
                          case 122:
                            linkTo = `/demandEdit/${item.relationID}`;
                            break;
                          // Diğer type'lar için gerekli case'leri ekleyin
                          case 123:
                            linkTo = `/offerEdit/${item.relationID}`;
                            break;
                          case 124:
                            linkTo = `/orderEdit/${item.relationID}`;
                            break;
                          case 125:
                            linkTo = `/invoiceEdit/${item.relationID}`;
                            break;
                          case 126:
                            linkTo = `/projectEdit/${item.relationID}`;
                            break;
                          case 127:
                            linkTo = `/taskDetailByAssignedUser/${item.relationID}`;
                            break;
                          case 128:
                            linkTo = `/formBuilderSchemaDetail/${item.relationID}`;
                            break;
                          case 129:
                            linkTo = `/stockCardEdit/${item.relationID}`;
                            break;
                          case 130:
                            linkTo = `/workFlowEdit/${item.relationID}`;
                            break;
                          case 3794:
                            linkTo = `/accountEdit/${item.relationID}`;
                            break;
                          default:
                            break;
                        }
                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 cursor-pointer ${rowClass}`}
                            onClick={() =>
                              handleClick(
                                item.notificationID,
                                item.customerID,
                                linkTo
                              )
                            }
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.notification}
                            </th>
                            <td className="px-6 py-4">{formatliTarih}</td>
                            <td className="px-6 py-4">{newClock}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <nav
                className="float-right my-5"
                aria-label="Page navigation example"
              >
                <ul className="flex items-center -space-x-px h-10 text-sm">
                  <li>
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      className={`flex items-center justify-center px-4 h-10 ${
                        currentPage === 1
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                    </button>
                  </li>
                  {renderPageNumbers()}
                  <li>
                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className={`flex items-center justify-center pl-4 h-10 ${
                        currentPage === totalPages
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500"
                      }`}
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="w-2.5 h-2.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Notifications;
